module.exports = [{
      plugin: require('../node_modules/@outdoormap/gatsby-plugin-brand-sites/gatsby-browser.js'),
      options: {"plugins":[],"apiUrl":"https://api.naturkartan.se","apiKey":"9c06412b-1af1-4f8f-aebb-8e94c9eccca1","guideId":"460","siteUrl":"https://www.vanerleden.se","lang":"sv","typesCategories":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"typesCategoriesIncluded":{"stages":["31"],"stays":["55","56"],"eatDrink":["54"],"service":["72","73","119"],"packages":["117"],"seeDo":[]},"imagePlaceholderColor":"#827700","templates":{"type":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/type.js","site":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/site.js","page":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/page.js","articles":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/articles.js","articlesPost":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/articles-post.js","news":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/news.js","newsPost":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/news-post.js","events":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/events.js","eventsPost":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/events-post.js","tours":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/tours.js","toursPost":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/tours-post.js","search":"/build/4ce01801-0251-4ccd-aeba-a7cefd6f8ae3/src/templates/search.js"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"lang":"sv","name":"Vanerleden","short_name":"Vanerleden","description":"Cykla runt Sveriges största sjö","theme_color":"#29292b","background_color":"#fff","icon":"static/meta-images/icon.png","start_url":"/","display":"standalone","cache_busting_mode":"none","include_favicon":false,"legacy":false,"theme_color_in_head":true,"crossOrigin":"anonymous","cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
